export default function ({ app, isServer, route, store, isDev }) {
  // How can we savely invalidate this?
  if (
    Object.keys(store.state.mainMenu).length === 0 ||
    Object.keys(store.state.footerMenu).length === 0 ||
    Object.keys(store.state.footerSubmenu).length === 0
  ) {
    return store.dispatch('loadMenus')
  }
}
